import { makeStyles } from '@material-ui/core/styles';

const useLoginStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  link: {
    textAlign: 'center',
    display: 'block',
    color: theme.palette.text.primary,
  },
  message: {
    padding: theme.spacing(4, 0),
  }
}));

export default useLoginStyles;
