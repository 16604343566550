import { useState } from "react"

const useFormInput = (initialValue = '', transformer) => {
  const [value, setValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(initialValue);
  const handleChange = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const transformed = transformer ? transformer(value) : value;
    setValue(transformed)
    e.target.checkValidity 
      ? setIsValid(e.target.checkValidity())
      : setIsValid(true);
  };
  return { isValid, value, onChange: handleChange, setValue };
}

export default useFormInput;