import React, { useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { navigate, Link } from 'gatsby';
import amplifyConfig from '../amplify';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Layout from '../components/layout';
import useFormInput from '../components/use-form-input';
import useLoginStyles from '../components/use-login-styles';

const config = amplifyConfig();
Amplify.configure(config);

function ResetPasswordPage({path}) {
  const classes = useLoginStyles();
  const username = useFormInput();
  const code = useFormInput();
  const password = useFormInput();
  const confirmPassword = useFormInput();
  const [form, setForm] = useState({ isSending: false,isSuccess: false, error: null }); 
  const isInvalidForm = (
    !username.isValid
    || !code.isValid 
    || !password.isValid 
    || !confirmPassword.isValid
  );

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (password.value !== confirmPassword.value) return setForm({...form, error: 'Passwords do not match'});
    setForm({ ...form, isSending: true });
    try {
      const res = await Auth.forgotPasswordSubmit(username.value, code.value, password.value);
      setForm({ ...form, isSending: false, isSuccess: true, error: null });
      navigate('/reset-password');
    } catch(error) {
      const message = error.message || 'An error occured, please try again. If the error persists, contact the administrator';
      setForm({ ...form, isSending: false, isSuccess: false, error: message });
    }
  }
  return (
    <Layout path={path}>
      <Container component="main" maxWidth="xs">
        { form.error && <Alert severity="error">{form.error}</Alert> }
        { form.isSuccess && <Alert severity="success">
          Your password has been reset successfully.
        </Alert> }
        { (!form.error && !form.isSuccess) && <Alert severity="success">
          We've sent you an email with a code to reset your password. Please fill the form below.
        </Alert> }
        <div className={classes.paper}>
          { !form.isSuccess && (
            <div>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              <form className={classes.form} noValidate>
                <TextField
                  inputProps={{
                    readOnly: Boolean(form.isSending),
                  }}
                  autoFocus
                  fullWidth
                  id="username"
                  label="Username"
                  margin="normal"
                  name="username"
                  required
                  type="text"
                  variant="outlined"
                  value={username.value}
                  onChange={username.onChange}
                />
                <TextField
                  inputProps={{
                    readOnly: Boolean(form.isSending),
                  }}
                  fullWidth
                  id="password"
                  label="Password"
                  margin="normal"
                  name="password"
                  required
                  type="password"
                  variant="outlined"
                  value={password.value}
                  onChange={password.onChange}
                />
                <TextField
                  inputProps={{
                    readOnly: Boolean(form.isSending),
                  }}
                  fullWidth
                  id="confirmPassword"
                  label="Confirm password"
                  margin="normal"
                  name="confirmPassword"
                  required
                  type="password"
                  variant="outlined"
                  value={confirmPassword.value}
                  onChange={confirmPassword.onChange}
                />
                <TextField
                  inputProps={{
                    readOnly: Boolean(form.isSending),
                  }}
                  fullWidth
                  id="code"
                  label="Code"
                  margin="normal"
                  name="code"
                  required
                  type="text"
                  variant="outlined"
                  value={code.value}
                  onChange={code.onChange}
                />
                <div></div>
                <Button
                  disabled={form.isSending || isInvalidForm}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleOnSubmit}
                >
                  { form.isSending ? 'Sending...' : 'Reset password' }
                </Button>
                <Button
                  disabled={form.isSending}
                  type="button"
                  component={Link}
                  to="/login"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  className={classes.submit}
                >
                  Cancel
                </Button>
              </form>
            </div>
          )}
          { form.isSuccess && (
            <Button
              type="button"
              component={Link}
              to="/login"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Log in
            </Button>
          )}
        </div>
      </Container>
    </Layout>
  );
}

export default ResetPasswordPage;
